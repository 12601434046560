import React from 'react';
import {
    Card, AppBar, Tabs, Tab,
    Typography, Box
} from '@material-ui/core'
import { } from 'antd';
import { Radio, Checkbox, Icon, message } from 'antd';
import { includes, filter, map } from 'lodash';
const a11yProps = index => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const zhongjingshuZhibiaoGridCode = {
    'ar': [
        { name: '9.8-11.0', color: 'hsl(0, 64%, 65%)' },
        { name: '24.0-25.0', color: 'hsl(3, 6%, 8%)' },
    ],
    'cd': [
        { name: '0-0.04', color: 'hsl(0, 64%, 65%)' },
        { name: '0.150-0.23', color: 'hsl(3, 6%, 8%)' },
    ],
    'cr': [
        { name: '50-54', color: 'hsl(0, 64%, 65%)' },
        { name: '89-90', color: 'hsl(3, 6%, 8%)' },
    ],
    'cu': [
        { name: '37-41', color: 'hsl(0, 64%, 65%)' },
        { name: '88-89', color: 'hsl(3, 6%, 8%)' },
    ],
    'hg': [
        { name: '0.049-0.051', color: 'hsl(0, 64%, 65%)' },
        { name: '0.083-0.086', color: 'hsl(3, 6%, 8%)' },
    ],
    'pb': [
        { name: '37-38', color: 'hsl(0, 64%, 65%)' },
        { name: '30-37', color: 'hsl(3, 6%, 8%)' },
    ],
    'zn': [
        { name: '102-104', color: 'hsl(0, 64%, 65%)' },
        { name: '145-146', color: 'hsl(3, 6%, 8%)' },
    ]
}
const shuizhiZhibiaoGridCode = {
    'no2': [
        { name: '1.18-1.2', color: 'hsl(0, 64%, 65%)' },
        { name: '4.3-4.7', color: 'hsl(3, 6%, 8%)' },
    ],
    'nh4': [
        { name: '6-7', color: 'hsl(0, 64%, 65%)' },
        { name: '23-33', color: 'hsl(3, 6%, 8%)' },
    ],
    'no3': [
        { name: '31-34', color: 'hsl(0, 64%, 65%)' },
        { name: '30-31', color: 'hsl(3, 6%, 8%)' },
    ],
    'po4': [
        { name: '1.2-1.35', color: 'hsl(0, 64%, 65%)' },
        { name: '69-72', color: 'hsl(3, 6%, 8%)' },
    ],
    'si': [
        { name: '24-26', color: 'hsl(0, 64%, 65%)' },
        { name: '151-152', color: 'hsl(3, 6%, 8%)' },
    ]
}
const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={4}>{children}</Box>
        </Typography>
    );
}
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};
const Tab1ChengjiWu = props => {
    const [currentType, setCurrentType] = React.useState('all')
    const [quanwanbiaocengCheckGroups, setQuanwanbiaocengCheckGroups] = React.useState([])
    const [zhongjingshuCheckGroups, setZhongjingshuCheckGroups] = React.useState([])
    const [play, setPlay] = React.useState(false)
    const [interval, setInterva] = React.useState(null)


    const cleanPopup = e => {
        if (props.onPopup) {
            props.onPopup(null)
        }
    }
    const onChange = e => {
        const currentType = e.target.value
        setCurrentType(currentType)

        if (props.onZhongjinshuChange) {
            props.onZhongjinshuChange([])
        }
        if (props.showChenji) {
            props.showChenji(true)
        }

        if (props.map) {
            props.onUpdateLegends(null)
            if (currentType === 'all') {
                cleanPopup()
                props.onUpdateLegends(props.chenjiLegends)
                props.map.setFilter('fujianxianchengjipoint', null)
                props.map.setFilter('chengji_id_text', null)
            } else {
                const filter = [
                    "all",
                    [
                        "match",
                        ["get", "p_type"],
                        [currentType],
                        true,
                        false
                    ]
                ]
                props.map.setFilter('fujianxianchengjipoint', filter)
                props.map.setFilter('chengji_id_text', filter)
                const features = props.map.queryRenderedFeatures(null, { layers: ['chengji_id_text'] })
                const tableData = []
                if (features && features.length > 0) {
                    for (const feature of features) {
                        const { properties, id } = feature
                        let dd = {
                            key: Math.random().toString(),
                            feature: feature
                        }
                        for (const key in properties) {
                            if (properties.hasOwnProperty(key)) {
                                const element = properties[key];
                                if (key.indexOf('p_') > -1 && element) {
                                    dd[key.replace('p_', '')] = element
                                }
                            }
                        }
                        tableData.push(dd)
                    }
                }
                props.onUpdateTables(tableData)
                if (currentType === 'dianxing') {
                    if (props.onPopup) {
                        props.onCard({
                            title: '典型潮滩表层',
                            type: 'images',
                            data: [
                                require('../../images/421575082846_.pic_hd.jpg'),
                                require('../../images/431575082847_.pic_hd.jpg')]
                        })
                    }
                } else {
                    cleanPopup()
                }
            }
        }
    }
    const options = [
        { label: '表层粒度', value: 'biaocenglidu' },
        { label: '重金属', value: 'zhongjingshu' },
    ];
    const zjsOptions = [
        { label: 'ar', value: 'ar' },
        { label: 'cd', value: 'cd' },
        { label: 'cr', value: 'cr' },
        { label: 'cu', value: 'cu' },
        { label: 'hg', value: 'hg' },
        { label: 'pb', value: 'pb' },
        { label: 'zn', value: 'zn' },
    ];
    const onZhongjinshuChange = e => {
        setZhongjingshuCheckGroups(e.target.value)
        if (props.onZhongjinshuChange) {
            props.onZhongjinshuChange(e.target.value)
            props.onUpdateLegends({
                type: 'lines',
                data: zhongjingshuZhibiaoGridCode[e.target.value]
            })
        }
    }
    const onQuanWanLiDuChange = checked => {
        setQuanwanbiaocengCheckGroups(checked)
        if (includes(checked, 'biaocenglidu')) {
            if (props.showWaterSize) {
                props.showWaterSize(true)
            }
        } else {
            if (props.showWaterSize) {
                props.showWaterSize(false)
            }
        }

    }
    const startPlay = () => {
        if (interval) {
            clearInterval(interval)
        }
        if (!play) {
            const layers = map(zjsOptions, 'value')
            let currentIndex = 0
            setInterva(setInterval(() => {
                if (currentIndex === layers.length) {
                    currentIndex = 0
                }
                onZhongjinshuChange({ target: { value: layers[currentIndex] } })
                currentIndex++
            }, 2000))
        }
        setPlay(!play)
    }
    return (<div>
        <Radio.Group defaultValue={currentType} onChange={onChange}>
            <Radio style={radioStyle} value={'all'}>全部数据</Radio>
            <Radio style={radioStyle} value={'全湾区域'}>全湾表层</Radio>
            {currentType === '全湾区域' ? <Checkbox.Group options={options} defaultValue={quanwanbiaocengCheckGroups} onChange={onQuanWanLiDuChange}></Checkbox.Group> : null}
            {currentType === '全湾区域' && includes(quanwanbiaocengCheckGroups, 'zhongjingshu') ? <div>
                <Radio.Group options={zjsOptions} value={zhongjingshuCheckGroups} defaultValue={zhongjingshuCheckGroups} onChange={onZhongjinshuChange}></Radio.Group>
                <Icon type={play ? 'pause-circle' : 'play-circle'} onClick={startPlay} style={{ fontSize: '20px', color: '#08c', marginTop: '10px', cursor: 'pointer' }} />
            </div> : null}
            {/* <Icon type="play-circle" /> */}

            <Radio style={radioStyle} value={'典型潮滩区域'}>典型潮滩表层</Radio>
        </Radio.Group>
    </div>);
}

const Tab2ShuiDongLi = props => {
    const [currentType, setCurrentType] = React.useState(1)

    return (<div style={{ fontSize: '14px' }}>
        通过对三都澳 3个定点潮汐水位站的洪枯季大小潮的观测，获取了三都澳不同径潮流相互作用潮汐特征。
鳌江和横屿区域，其淤积过程主要发生在涨潮过程，冲刷主要发生在落潮，航道码头应该建设在落潮优势区域，如果围垦改变了航道码头的落潮优势，则会发生淤积，目前横屿码头的淤积就是如此。
    </div>);
}
const Tab1Form = props => {
    const [radioType, setRadioType] = React.useState('type1')
    const onChange = e => {
        const currentType = e.target.value
        setRadioType(currentType)
        if (currentType === 'type1') {
            props.map.setFilter('shuiwen_id_text', [
                "all",
                [
                    "match",
                    ["get", "p_type"],
                    ['123'],
                    true,
                    false
                ]
            ])
            props.map.setFilter('chengji_id_text', null)
            props.showChenji(true)
            props.showShuiwen(false)
            props.onZhongjinshuChange([])
            props.onCard(null)
            props.onUpdateLegends({
                type: 'lines',
                data: [{ name: '典型潮滩区域', color: 'hsl(0, 94%, 54%)' },
                { name: '全湾区域', color: 'hsl(266, 94%, 48%)' }]
            })
        } else if (currentType === 'type2') {
            props.map.setFilter('shuiwen_id_text', null)
            props.map.setFilter('chengji_id_text', [
                "all",
                [
                    "match",
                    ["get", "p_type"],
                    ['123'],
                    true,
                    false
                ]
            ])
            props.showShuiwen(true)
            props.onZhongjinshuChange([])
            props.showChenji(false)
            props.onCard({
                title: '水动力',
                type: 'images',
                data: [
                    require('../../images/shuidongli/1.jpg'),
                    require('../../images/shuidongli/2.jpg'),
                    require('../../images/shuidongli/3.jpg'),
                    require('../../images/shuidongli/4.jpg'),
                    require('../../images/shuidongli/5.jpg'),
                ]
            })
        }
    }
    return (
        <div>
            <Radio.Group onChange={onChange} buttonStyle='outline' className="radio-menu" name="type" defaultValue={radioType} style={{ marginBottom: 8 }}>
                <Radio.Button value="type1">沉积物</Radio.Button>
                <Radio.Button value="type2">水动力</Radio.Button>
            </Radio.Group>
            <div>
                {radioType === 'type1' ? <Tab1ChengjiWu {...props} /> : null}
                {radioType === 'type2' ? <Tab2ShuiDongLi  {...props} /> : null}
            </div>

        </div>
    );
}
const Tab2Form = props => {
    const [currentType, setCurrentType] = React.useState('shuizhi')
    const [zhongjingshuCheckGroups, setZhongjingshuCheckGroups] = React.useState(null);
    const [play, setPlay] = React.useState(false)
    const [interval, setInterva] = React.useState(null)
    const onChange = e => {
        const currentType = e.target.value
        setCurrentType(currentType)
        if (currentType === 'shuizhi') {
            props.onShowZhuzhuang(false)
            props.showChenji(false)
            props.showShuiwen(false)
            props.showShuizhi(true)
            props.showPoints(['shuizhi_id_text'])
            props.hiddenPoints(['zhuzhuang_id_text'])
            props.onCard(null)
        } else if (currentType === 'zhuzhuangyang') {
            props.showPoints(['zhuzhuang_id_text'])
            props.hiddenPoints(['shuizhi_id_text'])
            props.showShuizhi(false)
            props.showShuiwen(false)
            props.onShowZhuzhuang(true)
            props.onCard({
                title: '柱状样',
                type: 'images',
                data: [
                    require('../../images/zhuzhuangyang/1.jpg'),
                    require('../../images/zhuzhuangyang/2.jpg'),
                    require('../../images/zhuzhuangyang/3.jpg'),
                    require('../../images/zhuzhuangyang/4.jpg'),
                    require('../../images/zhuzhuangyang/5.jpg'),
                    require('../../images/zhuzhuangyang/6.jpg'),
                    require('../../images/zhuzhuangyang/7.jpg'),
                ]
            })
        }
    }
    const zjsOptions = [
        { label: 'no2', value: 'no2' },
        { label: 'nh4', value: 'nh4' },
        { label: 'no3', value: 'no3' },
        { label: 'po4', value: 'po4' },
        { label: 'si', value: 'si' }
    ];
    const onFace = e => {
        setZhongjingshuCheckGroups(e.target.value)
        if (props.onFace) {
            props.onFace(e.target.value)
            props.onUpdateLegends({
                type: 'lines',
                data: shuizhiZhibiaoGridCode[e.target.value]
            })
        }
    }
    const startPlay = () => {
        if (interval) {
            clearInterval(interval)
        }
        if (!play) {
            const layers = map(zjsOptions, 'value')
            let currentIndex = 0
            setInterva(setInterval(() => {
                if (currentIndex === layers.length) {
                    currentIndex = 0
                }
                onFace({ target: { value: layers[currentIndex] } })
                currentIndex++
            }, 2000))
        }
        setPlay(!play)
    }
    return (
        <div>
            <Radio.Group name="type" defaultValue={currentType} onChange={onChange}>
                <Radio style={radioStyle} value="shuizhi">水质</Radio>
                {currentType === 'shuizhi' ? <div>
                    <Radio.Group options={zjsOptions} value={zhongjingshuCheckGroups} defaultValue={zhongjingshuCheckGroups} onChange={onFace}></Radio.Group>
                    <Icon type={play ? 'pause-circle' : 'play-circle'} onClick={startPlay} style={{ fontSize: '20px', color: '#08c', marginTop: '10px', cursor: 'pointer' }} />
                </div> : null}
                <Radio style={radioStyle} value="zhuzhuangyang">柱状样</Radio>
                {currentType === 'zhuzhuangyang' ? <div style={{ fontSize: '14px' }}>
                    三都澳按水深划分的高中潮滩、低潮滩、航道等不同地貌单元，其浅地层（1-1.5m）的沉积层理和颜色、不同沉积物含量、平均粒度、沉积物属性以及沉积速率都呈现显著差异。
                </div> : null}
            </Radio.Group>
            <div>
            </div>
        </div>
    );
}
const Tab3Form = props => {

    const [currentType, setCurrentType] = React.useState('type1')
    const [lineYears, setLineYears] = React.useState(map(props.anxianLegends, 'value'))
    const [play, setPlay] = React.useState(false)
    const [interval, setInterva] = React.useState(null)
    const updateLegends = lines => props.onUpdateLegends({
        type: 'lines',
        data: filter(props.anxianLegends, o => includes(lines ? lines : lineYears, o.value)).map(oo => {
            return {
                name: oo.label,
                color: oo.color
            }
        })
    })
    const onChange = e => {
        const currentType = e.target.value
        // if (currentType === 'type1') {
        //     props.showAnxian(true)
        //     updateLegends()
        // } else {
        //     props.showAnxian(false)
        // }
        props.showAnxian(true)
        updateLegends()
        setCurrentType(currentType)
    }


    const onLineYearsChange = d => {
        if (d.length === 0) {
            message.error('至少选中一项')
            return;
        }
        updateLegends(d)
        props.map.setFilter('anxian_line', [
            "all",
            [
                "match",
                ["get", "line_year"],
                d,
                true,
                false
            ]
        ])
        setLineYears(d)
    }

    const startPlay = () => {
        if (interval) {
            clearInterval(interval)
        }
        if (!play) {
            const layers = map(props.anxianLegends, 'value')
            let currentIndex = 0
            setInterva(setInterval(() => {
                if (currentIndex === layers.length) {
                    currentIndex = 0
                }
                props.map.setFilter('anxian_line', [
                    "all",
                    [
                        "match",
                        ["get", "line_year"],
                        [layers[currentIndex]],
                        true,
                        false
                    ]
                ])
                currentIndex++
            }, 2000))
        } else {
            props.map.setFilter('anxian_line', null)
        }
        setPlay(!play)
    }

    return (
        <div>
            <Radio.Group onChange={onChange} buttonStyle='outline' className="radio-menu" name="type" defaultValue={currentType} style={{ marginBottom: 8 }}>
                <Radio.Button value="type1">岸线变化</Radio.Button>
                <Radio.Button value="type2">围垦变化</Radio.Button>
            </Radio.Group>
            <div>
                <Checkbox.Group value={lineYears} options={props.anxianLegends} defaultValue={lineYears} onChange={onLineYearsChange}></Checkbox.Group>
                {currentType === 'type2' ? <Icon type={play ? 'pause-circle' : 'play-circle'} onClick={startPlay} style={{ fontSize: '20px', color: '#08c', marginTop: '10px', cursor: 'pointer' }} /> : null}
            </div>
            <div>
            </div>
        </div>
    );
}

export default class MainPanel extends React.Component {
    render() {
        return (
            <div>
                <Card className={' left-control-panel'}>
                    {this.props.tab === 0 ? <TabPanel value={this.props.tab} index={0}>
                        <Tab1Form {...this.props} />
                    </TabPanel> : null}
                    {this.props.tab === 1 ? <TabPanel value={this.props.tab} index={1}>
                        <Tab2Form {...this.props} />
                    </TabPanel> : null}
                    {this.props.tab === 2 ? <TabPanel value={this.props.tab} index={2}>
                        <Tab3Form {...this.props} />
                    </TabPanel> : null}
                </Card>
            </div>)
    }
}
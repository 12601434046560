import React from 'react';
import {
    Card
} from '@material-ui/core'
import './index.scss'
export default class LegendPanel extends React.Component {
    render() {
        if (this.props.legends) {
            const { type, data } = this.props.legends
            if (type === 'lines') {
                return (<Card className='legend-card'>
                    <div className='legend-card__title'><strong>图例</strong></div>
                    <div className='legend-cont'>
                        {data.map((item, index) => {
                            return <div key={index} style={{ paddingBottom: '10px' }}>
                                <div className='color-cont__item'>
                                    {item['name']}
                                </div>
                                <div className='color-line' style={{ background: item['color'] }}></div>

                            </div>
                        })}
                    </div>
                </Card>)
            }
        }
        return null
    }
}
import React from 'react';
import { Layer } from 'react-mapbox-gl';

export default class DefaultLayer extends React.Component {
    state = {
        showShuizhi: false,
        zhongjingshus: null,
        face: null,
        showChenji: true,
        showWaterSize: false,
        showZhuzhuang: false,
        showShuiWen: false,
        showAnxian: false,
    }

    refreshFace = face => this.setState({ face })
    refreshShowShuizhi = showShuizhi => this.setState({ showShuizhi })
    refreshZhongjinshus = zhongjingshus => this.setState({ zhongjingshus })
    refreshShowWaterSize = showWaterSize => this.setState({ showWaterSize })
    refreshShowZhuzhuang = showZhuzhuang => this.setState({ showZhuzhuang })
    refreshShowChenji = showChenji => this.setState({ showChenji })
    refreshShowShuiwen = showShuiWen => this.setState({ showShuiWen })
    refreshShowAnxian = showAnxian => this.setState({ showAnxian })

    currentRenderPointLayers = () => {
        const result = []
        if (this.state.showShuiWen) {
            result.push('fujianxianshuiwenpoint')
        }
        if (this.state.showShuizhi) {
            result.push('fujianxianshuizhipoint')
        }
        if (this.state.showChenji) {
            result.push('fujianxianchengjipoint')
        }
        if (this.state.showZhuzhuang) {
            result.push('fujianxianzhuzhuangpoint')
        }
        return result;
    }
    currentRenderPointSourceLayers = () => {
        const result = []
        if (this.state.showShuiWen) {
            result.push('shuiwen')
        }
        if (this.state.showShuizhi) {
            result.push('shuizhi')
        }
        if (this.state.showChenji) {
            result.push('chenji')
        }
        if (this.state.showZhuzhuang) {
            result.push('zhuzhuang')
        }
        return result;
    }
    render() {
        return [
            this.state.showAnxian ? <Layer
                id="anxian_line"
                sourceId="mbtiles_sansha"
                key="anxian_line"
                type="line"
                sourceLayer="anxian"
                paint={{
                    'line-color': [
                        "match",
                        ["get", "line_year"],
                        [1996],
                        "hsl(0, 96%, 51%)",
                        [2003],
                        "hsl(56, 100%, 53%)",
                        [2008],
                        "hsl(99, 93%, 48%)",
                        [2013],
                        "hsl(214, 83%, 47%)",
                        [2018],
                        "hsl(285, 95%, 51%)",
                        "#000000"
                    ],
                    'line-width': 3
                }}
            /> : null,

            this.state.showWaterSize ? <Layer
                id="zhongjineshu_face_size_layer"
                sourceId="mbtiles_sansha"
                key="zhongjineshu_face_size_layer_fill"
                type="fill"
                sourceLayer="face_size"
                paint={{
                    "fill-color": [
                        "interpolate",
                        ["linear"],
                        ["get", "gridcode"],
                        2,
                        "hsl(0, 64%, 65%)",
                        15,
                        "hsl(3, 6%, 8%)"
                    ],
                    'fill-opacity': 0.7
                }}
            /> : null,
            this.state.face ? <Layer
                id={`face_${this.state.face}_layer`}
                sourceId="mbtiles_sansha"
                key={`facelayer_${this.state.face}_fill`}
                type="fill"
                sourceLayer={`face_${this.state.face}`}
                paint={{
                    "fill-color": [
                        "interpolate",
                        ["linear"],
                        ["get", "gridcode"],
                        2,
                        "hsl(0, 64%, 65%)",
                        15,
                        "hsl(3, 6%, 8%)"
                    ],
                    'fill-opacity': 0.7
                }}
            /> : null,
            this.state.zhongjingshus ? <Layer
                id={`zhongjineshu_${this.state.zhongjingshus}_layer`}
                sourceId="mbtiles_sansha"
                key={`zhongjineshulayer_${this.state.zhongjingshus}_fill`}
                type="fill"
                sourceLayer={`water_${this.state.zhongjingshus}`}
                paint={{
                    "fill-color": [
                        "interpolate",
                        ["linear"],
                        ["get", "gridcode"],
                        2,
                        "hsl(0, 64%, 65%)",
                        15,
                        "hsl(3, 6%, 8%)"
                    ],
                    'fill-opacity': 0.7
                }}
            /> : null,
            this.state.showShuiWen ? <Layer
                id="fujianxianshuiwenpoint"
                sourceId="mbtiles_sansha"
                key="fujianxianshuiwen_point"
                type="circle"
                sourceLayer="shuiwen"
                paint={{
                    'circle-color': 'red',
                    'circle-radius': [
                        "match",
                        ["feature-state", "selected"],
                        ['true'],
                        10,
                        ['false'],
                        4,
                        4
                    ],
                }}
            /> : null,
            this.state.showShuizhi ? <Layer
                id="fujianxianshuizhipoint"
                sourceId="mbtiles_sansha"
                key="fujianxianshuizhi_point"
                type="circle"
                sourceLayer="shuizhi"
                paint={{
                    'circle-color': 'yellow',
                    'circle-radius': [
                        "match",
                        ["feature-state", "selected"],
                        ['true'],
                        10,
                        ['false'],
                        4,
                        4
                    ],
                }}
            /> : null,
            this.state.showChenji ? <Layer
                id="fujianxianchengjipoint"
                sourceId="mbtiles_sansha"
                key="fujianxianchengjipoint_point"
                type="circle"
                sourceLayer="chenji"
                paint={{
                    'circle-color': [
                        "match",
                        ["get", "p_type"],
                        ["典型潮滩区域"],
                        "hsl(0, 94%, 54%)",
                        ["全湾区域"],
                        "hsl(266, 94%, 48%)",
                        "hsla(0, 0%, 0%, 0)"
                    ],
                    'circle-radius': [
                        "match",
                        ["feature-state", "selected"],
                        ['true'],
                        10,
                        ['false'],
                        4,
                        4
                    ],
                }}
            /> : null,
            this.state.showZhuzhuang ? <Layer
                id="fujianxianzhuzhuangpoint"
                sourceId="mbtiles_sansha"
                key="fujianxianzhuzhuangpoint_point"
                type="circle"
                sourceLayer="zhuzhuang"
                paint={{
                    'circle-color': 'green',
                    'circle-radius': [
                        "match",
                        ["feature-state", "selected"],
                        ['true'],
                        10,
                        ['false'],
                        4,
                        4
                    ],
                }}
            /> : null,

        ].map(layer => layer)
    }
}
import React from 'react';
import './App.scss';
import Config from './config/config';
import DrawControl from 'react-mapbox-gl-draw';
// Don't forget to import the CSS
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import ReactMapboxGl, {
  Popup,
  ZoomControl,
  ScaleControl
} from 'react-mapbox-gl';
import { DefaultLayer } from './components/layers';
import { MainPanel, LegendPanel } from './components/controls';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Carousel, Card, Table, Icon } from 'antd';
import { Container, Box, AppBar } from '@material-ui/core';
const isDev = process.env.NODE_ENV === 'development'

const mapDevProps = () => {
  return {
    center: Config.center
  }
  // if (isDev) {
  //   return {
  //     center: Config.center
  //   }
  // }
  // return {}
}
const tableSorts = (key) => {
  const trans = {
    '类型': 3,
    '日期': 2,
    '描述': 4,
    '编号': 1
  }
  const tran = trans[key]
  if (tran) {
    return tran
  }
  return 0
}
const transTable = (key) => {
  const trans = {
    'type': '类型',
    'date': '日期',
    'describtion': '描述',
    'id': '编号'
  }
  const tran = trans[key]
  if (tran) {
    return tran
  }
  return ''
}
const Map = ReactMapboxGl({
  accessToken: Config.accessToken,
});

// p_date: "201903"
// p_describtion: "三沙湾全湾表层沉积物分布呈现多条径潮流辐集区域（三都岛北侧霍童溪、白马港和盐田港三条河流与潮流辐集区域；三都岛南侧飞鸾湾、大金溪等河流与潮流的辐集区域）粒度粗，而在潮流幅散海湾区域（东冲半岛内湾，飞鸾湾、横屿潮滩）粒度细的总体特征"
// p_id: "nddz-24"
// p_type: "全湾区域"
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    render: text => <a>{text}</a>,
  },
  {
    title: '日期',
    dataIndex: 'date',
    width: 100,
    key: 'date',
  },
  {
    title: '类型',
    width: 100,
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '描述',
    width: 1000,
    dataIndex: 'describtion',
    key: 'describtion',
  }
];
const chenjiLegends = {
  type: 'lines',
  data: [{ name: '典型潮滩区域', color: 'hsl(0, 94%, 54%)' },
  { name: '全湾区域', color: 'hsl(266, 94%, 48%)' }]
}
const anxianLegends = [
  { label: '1996年', name: '1996年', value: 1996, color: 'hsl(0, 96%, 51%)' },
  { label: '2003年', name: '2003年', value: 2003, color: 'hsl(56, 100%, 53%)' },
  { label: '2008年', name: '2008年', value: 2008, color: 'hsl(99, 93%, 48%)' },
  { label: '2013年', name: '2013年', value: 2013, color: 'hsl(214, 83%, 47%)' },
  { label: '2018年', name: '2018年', value: 2018, color: 'hsl(285, 95%, 51%)' },
]
export default class App extends React.Component {
  defaultLayer = React.createRef()
  state = {
    styleIndex: 0,
    map: null,
    popup: null,
    popLocation: null,
    rightCard: null,
    legends: chenjiLegends,
    tableData: [],
    tab: 0,
    lastSelectedFeatureId: null,
  }
  onMapStyleLoaded = (map) => {
    map.doubleClickZoom.disable()

    this.setState({ map })
    map.flyTo({
      // These options control the ending camera position: centered at
      // the target, at zoom level 9, and north up.
      center: Config.center,
      zoom: 10.5,
      bearing: 0,

      // These options control the flight curve, making it move
      // slowly and zoom out almost completely before starting
      // to pan.
      speed: 1.2, // make the flying slow
      curve: 1, // change the speed at which it zooms out

      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: function (t) { return t; }
    })
  }
  onSourceData = () => { }
  cleanFeatureSelectState = (sourceLayers) => {
    if (!sourceLayers) {
      sourceLayers = this.defaultLayer.currentRenderPointLayers()
    }
    const lastSelectedFeatureId = this.state.lastSelectedFeatureId
    if (lastSelectedFeatureId) {
      for (const layer of sourceLayers) {
        this.state.map.setFeatureState({
          source: 'mbtiles_sansha',
          sourceLayer: layer,
          id: lastSelectedFeatureId,
        }, { selected: 'false' })
      }
    }
  }
  setFeatureSelectedState = id => {
    const sourceLayers = this.defaultLayer.currentRenderPointSourceLayers()
    this.cleanFeatureSelectState(sourceLayers)
    for (const layer of sourceLayers) {
      this.state.map.setFeatureState({
        source: 'mbtiles_sansha',
        sourceLayer: layer, id
      }, { selected: 'true' });
    }
    this.setState({
      lastSelectedFeatureId: id
    })
  }
  setPropertiesPopup = (properties, lng, lat) => {
    const popupTable = []
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        const element = properties[key];
        if (key.indexOf('p_') > -1 && element) {
          popupTable.push({
            label: key.replace('p_', ''),
            value: element
          })
        }
      }
    }
    if (popupTable.length > 0) {
      this.onPopup({
        type: 'table',
        data: popupTable
      }, [lng, lat])
    }
  }
  onMapClick = (map, e) => {
    if (this.defaultLayer) {
      const pointsLayers = this.defaultLayer.currentRenderPointLayers()
      if (pointsLayers && pointsLayers.length > 0) {
        const bbox = [[e.point.x - 5, e.point.y - 5], [e.point.x + 5, e.point.y + 5]];
        const features = map.queryRenderedFeatures(bbox, {
          layers: pointsLayers
        });

        if (features && features.length > 0) {
          const firstFeature = features[0]
          const { properties, id } = firstFeature
          this.setFeatureSelectedState(id)
          this.setPropertiesPopup(properties, e.lngLat.lng, e.lngLat.lat)
        }
      }
    }
  }
  onMapDblClick = () => { }
  onZoomEnd = () => { }
  onDrawCreate = () => { }
  onDrawUpdate = () => { }
  onDrawModeChange = () => { }
  changeMapStyle = () => { }

  getPopup = () => {
    if (this.state.popup && this.state.popLocation) {
      const { type, data } = this.state.popup
      if (type === 'table') {
        return <Popup
          coordinates={this.state.popLocation}
          offset={{
            'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
          }}>
          <div className='popup-container'>
            <div className='popup-container__title'>属性</div>
            {data.map((item, index) => {
              return {
                label: transTable(item['label']),
                value: item['value']
              }
            }).sort((a, b) => {
              return (tableSorts(a.label) > tableSorts(b.label)) ? 1 : -1
            }).map((item, index) => {
              // eslint-disable-next-line jsx-a11y/alt-text
              return <div className='popup_item' key={index}>
                <span className='popup_item__title'>{item.label}: </span>
                <span className='popup_item__value'>{item.value}</span>
              </div>
            })}
          </div>
        </Popup>
      }
    }

    return null
  }
  getCard = () => {
    if (this.state.rightCard) {
      const { title, type, data } = this.state.rightCard
      if (type === 'images') {
        return <Card title={title} bordered={false} style={{ width: 600 }}>
          <Carousel autoplay>
            {data.map((image, index) => {
              // eslint-disable-next-line jsx-a11y/alt-text
              return <img key={index} src={image} />
            })}
          </Carousel>
        </Card>
      }

    }
    return null
  }
  getTable = () => {
    if (this.state.tableData && this.state.tableData.length > 0) {
      const { tableData } = this.state
      return <Card title="数据栏" style={{ maxHeight: "600px" }} extra={<Icon type="close" onClick={e => this.setState({ tableData: [] })} />}>
        <Table onRow={this.onRow} scroll={{ x: 400, y: 150 }} stickyHeader columns={columns} dataSource={tableData} style={{ maxWidth: '400px' }} />
      </Card>
    }
    return null
  }
  onRow = record => {
    return {
      onClick: event => {
        const { feature: { id, properties } } = record
        this.setFeatureSelectedState(id)
        this.setPropertiesPopup(properties, properties.log, properties.lat)
        this.state.map.flyTo({
          // These options control the ending camera position: centered at
          // the target, at zoom level 9, and north up.
          center: [properties.log, properties.lat],
          zoom: 10.5,
          bearing: 0,

          // These options control the flight curve, making it move
          // slowly and zoom out almost completely before starting
          // to pan.
          speed: 1.2, // make the flying slow
          curve: 1, // change the speed at which it zooms out

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: function (t) { return t; }
        })
      }
    }
  }
  onPopup = (popup, popLocation) => this.setState({ popup, popLocation })
  onCard = rightCard => this.setState({ rightCard })
  onZhongjinshuChange = names => {
    this.defaultLayer.refreshZhongjinshus(names)
  }
  onFace = face => {
    this.defaultLayer.refreshFace(face)
  }
  showShuiwen = isShow => {
    this.defaultLayer.refreshShowShuiwen(isShow)
  }
  showChenji = isShow => {
    this.defaultLayer.refreshShowChenji(isShow)
  }
  showWaterSize = isShow => {
    this.defaultLayer.refreshShowWaterSize(isShow)
  }
  showShuizhi = isShow => {
    this.defaultLayer.refreshShowShuizhi(isShow)
  }
  onShowZhuzhuang = isShow => {
    this.defaultLayer.refreshShowZhuzhuang(isShow)
  }
  showAnxian = isShow => {
    this.defaultLayer.refreshShowAnxian(isShow)
  }
  onUpdateLegends = legends => this.setState({ legends })
  onUpdateTables = tableData => this.setState({ tableData })

  hiddenPoints = (layers) => {
    for (const layer of layers) {
      this.state.map.setFilter(layer, [
        "all",
        [
          "match",
          ["get", "p_type"],
          ['123123'],
          true,
          false
        ]
      ])
    }
  }
  showPoints = layers => {
    for (const layer of layers) {
      this.state.map.setFilter(layer, null)
    }
  }
  handleTabChange = (event, tab) => {
    this.showChenji(false)
    this.onPopup(null)
    this.onCard(null)
    this.onFace(null)
    this.onUpdateTables([])
    this.onUpdateLegends(null)
    this.onZhongjinshuChange(null)
    this.showWaterSize(false)
    this.showShuizhi(false)
    this.onShowZhuzhuang(false)
    this.onUpdateLegends(null)
    this.showAnxian(false)
    this.setState({ tab, legends: [] })
    switch (tab) {
      case 0:
        this.showPoints(['chengji_id_text'])
        this.hiddenPoints(['shuizhi_id_text'])
        this.showChenji(true)
        this.setState({ tab, legends: chenjiLegends })
        break;
      case 1:
        this.showPoints(['shuizhi_id_text'])
        this.hiddenPoints(['chengji_id_text', 'shuiwen_id_text'])
        this.showShuizhi(true)
        break;
      case 2:
        this.hiddenPoints(['chengji_id_text', 'shuiwen_id_text', 'zhuzhuang_id_text', 'shuizhi_id_text'])
        this.showAnxian(true)
        this.onUpdateLegends({
          type: 'lines',
          data: anxianLegends
        })
        // this.tab3Ref.updateLegends()
        break;
      default:

        break;
    }
  }
  render() {
    return (
      <div className="root-sub">
        <div>
          <h1 className="app-title">
            福建三沙湾自然环境变化可视化平台
          </h1>
        </div>
        <AppBar position="relative">
          <Tabs
            value={this.state.tab}
            onChange={this.handleTabChange}
          >
            <Tab label="动力沉积" value={0} />
            <Tab label="环境质量" value={1} />
            <Tab label="地貌数据" value={2} />
            <Tab label="项目简介" value={3} />
          </Tabs>
        </AppBar>
        <div style={{
          flex: 1,
          position: "relative"
        }}>
          <Map
            onStyleLoad={this.onMapStyleLoaded}
            onSourceData={this.onSourceData}
            onClick={this.onMapClick}
            onDblClick={this.onMapDblClick}
            style={Config.styles[this.state.styleIndex].style}
            onZoomEnd={this.onZoomEnd}
            {...mapDevProps()}
            // onMouseMove={props.showPopupHander}
            // zoom={[props.zoom]}
            containerStyle={{
              top: 0,
              left: 0,
              position: 'absolute',
              height: '100%',
              width: '100%'
            }}
          >
            <ZoomControl position={'top-right'} />
            <ScaleControl style={{ 'bottom': 10, 'right': 10, top: 'auto' }} position={'top-right'} />
            <DrawControl position={'top-right'}
              onDrawCreate={this.onDrawCreate}
              onDrawUpdate={this.onDrawUpdate}
              onDrawModeChange={this.onDrawModeChange} />
            <DefaultLayer ref={r => this.defaultLayer = r} />
            <div className="left-top-conationer">
              <MainPanel {...this.state} onPopup={this.onPopup}
                showWaterSize={this.showWaterSize}
                onCard={this.onCard}
                showChenji={this.showChenji}
                showPoints={this.showPoints}
                hiddenPoints={this.hiddenPoints}
                onUpdateTables={this.onUpdateTables}
                anxianLegends={anxianLegends}
                showShuizhi={this.showShuizhi}
                chenjiLegends={chenjiLegends}
                onUpdateLegends={this.onUpdateLegends}
                showShuiwen={this.showShuiwen}
                showAnxian={this.showAnxian}
                onFace={this.onFace}
                onShowZhuzhuang={this.onShowZhuzhuang}
                onZhongjinshuChange={this.onZhongjinshuChange}
              />
            </div>
            <div className="right-top-container">
              {this.getCard()}
            </div>
            <div className="left-bottom-container">
              {this.getTable()}
            </div>
            {this.getPopup()}
            <div className="right-bottom-conatiner">
              <LegendPanel legends={this.state.legends} />
              <div className="map-style">
                {Config.styles.map((item, index) => {
                  return (<div className={'mapStyle ' + (this.state.styleIndex === index ? 'active' : '')}
                    onClick={e => this.setState({
                      styleIndex: index
                    })} key={index}>
                    <div className="mapStyle-cont">
                      {item.preview.length > 0 ? <div className="map-preview">
                        <img src={item.preview} alt='' />
                      </div> : ''}
                      <div className="map-name">{item.name}</div>
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </Map>

        </div>
      </div>
    )
  }
}
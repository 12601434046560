const isDev = process.env.NODE_ENV === 'development'
const stylePrefix = isDev ? 'dev' : 'prod'
const config = {
    accessToken: 'pk.eyJ1IjoiY3NpMG4iLCJhIjoiY2piMWkyeHJmMjN0NzJxcWtnaXkwenI5dyJ9.BzW8wKThr51MpmiKHi4j_w',
    center: [119.8, 26.7],
    styles: [{
        style: require(`../styles/${stylePrefix}/streets.json`),
        name: '行政区域',
        preview: 'http://shz.einsh.com:3000/static/media/mapbox.streets.77b02703.jpg'
    }, {
        style: require(`../styles/${stylePrefix}/satellite.json`),
        name: '卫星图',
        preview: 'http://shz.einsh.com:3000/static/media/mapbox.satellite.9fc416c7.png'
    }]
}

export default config;